import { useState } from 'react';
import Modal from './Modal'
import './App.css';
import cover from './assets/cover.jpg'
import moment from 'moment';
import gameList from './game-list'
import emailIcon from './assets/email-icon.png'
import discordIcon from './assets/discord-icon.png'
import blueskyIcon from './assets/bluesky-icon.png'
import tara from './assets/tara.jpg'
import scout from './assets/scout.jpg'
import amer from './assets/ameranthine.jpg'
import boqeh from './assets/boqeh.jpg'
import aj from './assets/aj.jpg'
import kim from './assets/kim.jpg'
import chase from './assets/chase.jpg'
import tom from './assets/tom.jpeg'
import suggest from './assets/writing.png'
import onlineNow from './assets/onlineNow.gif'
import dice from './assets/dice.png'
import controller from './assets/controller.png'
const friends = {
  ordered:[{
    name:'AJ Fillari',
    link:'https://bsky.app/profile/ajfillari.bsky.social',
    image: aj,
  },
  {
    name:'Kim',
    link:'https://bsky.app/profile/ohkimmies.bsky.social',
    image:kim
  },
  {
    name:'Chase Allhart',
    link:'https://www.podtimism.com/',
    image:chase,
  }],
  random:[
  {
    name:'Tara Crawford',
    link:'https://www.youtube.com/c/TaraCrawford',
    image:tara
  },
  {
    name:'Scout Wilkinson',
    link:'https://www.ko-fi.com/humblegoat',
    image:scout
  },
  {
    name:'boqeh',
    link:'https://boqeh.bandcamp.com/',
    image:boqeh
  },
  {
    name:' _amrnthne',
    link:'https://amaranthine.bandcamp.com/',
    image:amer
  },
  {
    name:'Tom',
    link:'https://www.myspace.com',
    image:tom
  }]
}

friends.random.sort((a,b) => 0.5 - Math.random());
const startDate = moment("2024-10-15");
const currentDate = moment();
const dateOffset = Math.floor((currentDate.diff(startDate, 'days')) / 7)
const currentGames = gameList.slice(dateOffset, dateOffset+4)


function App() {
  const [isModalOpen, setIsModalOpen] = useState(false)
  return (
    <div className="App">
      {isModalOpen && (
        <div className="overlay" onClick={() => {setIsModalOpen(false)}}></div>
      )}
      {isModalOpen && (
        <Modal closeFunction={setIsModalOpen}/>
      )}
      <div className="container">
        <div className="layout-half smaller">
          <div className="box bio">
            <b>dotzip</b>
            <div className="profile-picture">
              <img src={cover} alt="podcast art for dotzip but it looks like a myspace profile"/>
              <div className="profile-text">
                <p className="motto">":-)"</p>
                <p>Podcast</p>
                <p>0 years old</p>
                <p>Pennsylvania, California and Washington</p>
                <p>United States</p>
                <div className="last-login">
                  <img className="online-now" src={onlineNow} alt="online now"/>
                </div>
              </div>
            </div>
          </div>
          <div className="box contact">
            <b>Contacting dotzip</b>
            <div className='contact-methods'>
              <a href="mailto: dotziponline@gmail.com">
                <img className="icon" alt="email icon" src={emailIcon}/>Email Us
              </a>
              <a href="https://discord.gg/7j2xYbhMQP">
                <img className="icon" alt="discord icon" src={discordIcon}/>Join Our Discord
              </a>
              <a href="https://bsky.app/profile/dotzip.online">
                <img className="icon" alt="bluesky icon" src={blueskyIcon}/>Follow on Bluesky
              </a>
              <p onClick={() => {setIsModalOpen(true)}}>
                <img className="icon" alt="suggesting icon" src={suggest}/>Suggest a Game
              </p>
            </div>
          </div>
          <div className="box the-list">
            <b>the.list</b>
            {currentGames.map((currentGame, index)=>{
              return (
                <p key={index}>{currentGame.date}: 
                  <a href={currentGame.link}> {currentGame.name}
                  <img src={currentGame.type === 'game' ? controller : dice} alt="icon for the type of game"/>
                </a></p>
              )
            })}
          </div>
          <div className="box">
            <iframe width="100%" height="180px" title="podcast feed" frameBorder="no" scrolling="no" seamless="" src="https://share.transistor.fm/e/dotzip/latest"></iframe>
          </div>
        </div>
        <div className="layout-half">
          <div className="box network">
            <p>dotzip is in your extended network</p>
          </div>
          <div className="blurbs box">
            <b>dotzip's Blurbs</b>
            <div className="blurbs-content">
              <b>About us:</b>
              <p>Hey! We're dotzip, a podcast about big games in small packages.</p>
              <p>We typically cover shorter indie titles across video games and tabletop RPGs</p>
              <b>Feel free to drop us a line and suggest something new for us to play!</b>
              <p><b><a rel="noreferrer" target="_blank" href="https://ko-fi.com/dotzip">Support the show on kofi!</a></b></p>
            </div>
          </div>
          <div className="friends box">
            <b>dotzip's Friends</b>
            <div className="friends-content">
              <b>dotzip has 8 friends.</b>
              <div className="friends-list">
                {friends.ordered.map(orderedPerson => {
                  return (
                    <a href={orderedPerson.link} key={orderedPerson.name}>
                      {orderedPerson.name}
                      <img src={orderedPerson.image} alt={orderedPerson.name}/>
                    </a>
                  )
                })}
                {friends.random.map(randomPerson => {
                  return (
                    <a href={randomPerson.link} key={randomPerson.name}>
                      {randomPerson.name}
                      <img src={randomPerson.image} alt={randomPerson.name}/>
                    </a>
                  )
                })}
              </div>
            </div>
          </div>
          <div className="videos box">
            <b>dotzip's Videos</b>
            <div className="friends-content">
              <iframe width="100%" src="https://www.youtube.com/embed/vWJBejruPwI?si=_t008uwkm3TfVgxb" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
